<script lang="ts" setup>
const emits = defineEmits(['change-favorite-state']);
const props = defineProps<{ offerId: number }>();
const catalogStore = useCatalogStore();

const isAddedToFavorites = computed(() => catalogStore.favoriteOffersIds.includes(props.offerId));
const isLoading = ref(false);

async function switchFavoriteState() {
  if (isLoading.value) return;

  isLoading.value = true;
  await catalogStore.toggleFavorite(props.offerId);
  emits('change-favorite-state', isAddedToFavorites.value);
  isLoading.value = false;
}
</script>

<template>
  <button
    name="favorite-button"
    @click.stop="switchFavoriteState"
  >
    <SvgoHeart
      :class="{ 'fill-label-red': isAddedToFavorites }"
      class="text-2xl text-hell-black-60 hover:fill-hell-black-20"
      filled
    />
  </button>
</template>
